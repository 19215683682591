<template>
	<div class="preview-grid-clip" :style="{ height: gridHeight + 'px' }">
		<div ref="ref_previewGridWrap" class="preview-grid-wrap">
			<div ref="ref_previewGrid" class="preview-grid" :style="{ width: gridWidth + 'px' }">
				<ArtThumb
					v-for="(itm, i) in itms"
					:key="i"
					:itm="itm"
					:showInfo="false"
					imgSize="small"
					:width="itm ? itm.width : null"
					:height="itm ? itm.height : null"
					:inGrid="false"
					:parentEntity="featuredStore.rooms[roomIndex]"
					:hideBtns="true"
					:eager="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>
// Stores
import { useFeaturedStore } from '@/stores/FeaturedStore'

// Components
import ArtThumb from '@/components/ArtThumb'

// Internal
import fitGrid from '@/use/FitGrid'
// import { domLog } from '@/use/Helpers'

export default {
	name: 'FeatureGrid',
	components: {
		ArtThumb,
	},
	props: {
		itms: Array,
		// This lets ArtThumb know where to find the itms.
		roomIndex: Number,
	},
	setup() {
		const featuredStore = useFeaturedStore()
		return { featuredStore }
	},
	data() {
		return {
			// Dimensions of the grid as set by fitGrid()
			gridWidth: 0,
			gridHeight: 406, // Average starting value to avoid big DOM movement.

			// The scrollLeft value needed for the grid
			// to be centered on small screens.
			offsetCentered: null,

			// The distance we let the grid
			// horizontally auto-scroll.
			slideDistance: 50,

			// The vertical scroll values now,
			// before, and before that.
			// See onScrollH() for more info.
			scrollV: null,
			prevScrollV: null,
			prevPrevScrollV: null,
		}
	},

	// Client-side only:
	mounted() {
		// Fit grid.
		const { gridWidth, gridHeight } = fitGrid(this.itms, { rowCount: 2 })
		this.gridWidth = Math.ceil(gridWidth)
		this.gridHeight = Math.ceil(gridHeight)

		// Calculate grid offset for it to be centered.
		this.offsetCentered = this.getOffsetCentered()

		// Set the grid scroll starting position.
		// Immediately assigning the value doesn't work so we wait for the next frame.
		window.requestAnimationFrame(this.onScrollV)

		// Activate horizontal autoScroll
		this.startAutoScroll()

		// Disable horizontal auto-scroll as soon as user interferes.
		this.$refs.ref_previewGridWrap.addEventListener('scroll', this.onScrollH)
	},

	// When the keep-alive page is reactivated.
	activated() {
		// Set the grid scroll starting position.
		this.onScrollV()
	},

	beforeUnmount() {
		this.stopAutoScroll()
	},

	methods: {
		// Start horizontal auto-scroll.
		// - - -
		// Note: we're not using the windowStore.listen method because
		// we don't want any throttle and because we're combining it with
		// div scroll handling, it's easier to read like this.
		startAutoScroll() {
			window.addEventListener('scroll', this.onScrollV)
		},

		// Stop horizontal auto-scroll.
		stopAutoScroll() {
			window.removeEventListener('scroll', this.onScrollV)
			this.$refs.ref_previewGridWrap.removeEventListener('scroll', this.onScrollH)
		},

		// Vertical scroll listener.
		// - - -
		// The grid will start with a certain distance (this.slideDistance)
		// left of the center, then as soon as it pops into the viewport,
		// it will start scrolling to the center. The center is reached when
		// the top of the grid reaches the top of the vieport, after which
		// it will continue scrolling past its center.
		onScrollV() {
			// Get top side of the grid.
			const { top: gridY } = this.$refs.ref_previewGridWrap.getBoundingClientRect()

			// Get grid height.
			let { height: gridHeight } = this.$refs.ref_previewGrid.getBoundingClientRect()
			gridHeight -= 30 // Compensate for bottom padding buffer we use to hide the scroll bar.

			// The start and end points of the area that triggers auto-scroll (full viewport best.)
			const startY = window.innerHeight
			const endY = 0

			// Scroll the grid for as long it's in the viewport.
			if (gridY < startY) {
				const zone = startY - endY
				const pct = (gridY - endY) / zone
				const newScroll = this.offsetCentered - this.slideDistance * pct
				this.$refs.ref_previewGridWrap.scrollLeft = newScroll
				// if (this.roomIndex == 1) console.log(Math.round(pct * 10000) / 100, gridY)

				// Stop autoscroll once the grid has been scrolled out of the viewport.
				if (gridY + gridHeight < endY) this.stopAutoScroll()
			}
		},

		// Horizontal scroll listener
		// - - -
		// When we detect horizontal scrolling without vertical scrolling,
		// it means the user is scrolling the grid, at which point we remove
		// the auto-scrolling. We compare the current value with the one before
		// the previous one, because the last two can be the same at the end
		// when the scroll speed has slowed down to near zero.
		onScrollH() {
			this.prevPrevScrollV = this.prevScrollV
			this.prevScrollV = this.scrollV
			this.scrollV = window.scrollY
			if (this.scrollV !== null && this.scrollV == this.prevPrevScrollV) this.stopAutoScroll()
		},

		// The offset required for the grid to be centered.
		getOffsetCentered() {
			const { clientWidth: wrapWidth } = this.$refs.ref_previewGridWrap
			return (this.gridWidth - wrapWidth) / 2
		},
	},
}
</script>

<style scoped lang="scss">
// A wrapper just meant to clip the scroll bar.
.preview-grid-clip {
	// border: solid 1px red;
	margin: 0 calc(var(--pp) * -1);
	overflow: hidden;
	display: flex;
	justify-content: center;
}
.preview-grid-wrap {
	// background: lightblue;
	// border: solid 1px blue;
	width: 100%;
	height: calc(100% + 30px); // Make room for scroll bar.
	overflow-x: scroll;
	overflow-y: hidden;
}
.preview-grid {
	// background: red;
	display: flex;
	flex-wrap: wrap;
	gap: 3px;
	padding: 0 3px;
	box-sizing: content-box;
	margin: 0 auto;
}
</style>
