<template>
	<!-- <FeatureSkeleton /> -->
	<div v-for="(room, i) in featuredStore.rooms" :key="i" class="feature">
		<FeatureGrid :itms="room.feed.itms" :roomIndex="i" />
		<div class="content" :class="{ center: !room.intro || !room.intro.length }">
			<router-link :to="{ name: 'Room', params: { id: room.id } }" class="incog">
				<h2>{{ room.name }}</h2>
			</router-link>
			<BaseRoomDescription :room="room" />
			<RoomActions :room="room" />
			<div
				class="intro"
				:class="{ expandable: room.needsTrim, expand: room.expand }"
				v-html="featuredStore.htmlIntros[i]"
			></div>
			<a
				v-if="room.needsTrim"
				href="#"
				class="read-more"
				@click.prevent="() => featuredStore.toggleExpand(i)"
			></a>
		</div>
		<BaseIcon name="arthur-emblem" />
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'
import { useFeaturedStore } from '@/stores/FeaturedStore'

// Components
import FeatureGrid from '@/components/FeatureGrid'
// import FeatureSkeleton from '@/components/FeatureSkeleton'
import BaseIcon from '@/components/BaseIcon'
import BaseRoomDescription from '@/components/BaseRoomDescription'
import RoomActions from '@/components/RoomActions'

// Internal
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'HomeFeatured',
	components: {
		FeatureGrid,
		// FeatureSkeleton,
		BaseIcon,
		BaseRoomDescription,
		RoomActions,
	},
	setup() {
		const apiStore = useApiStore()
		const editorialApi = apiStore.loadApi('editorial')
		const featuredStore = useFeaturedStore()
		return { editorialApi, featuredStore }
	},
	created() {
		this.loadFeaturedRooms()
	},
	methods: {
		// Load rooms
		async loadFeaturedRooms() {
			const response = await this.editorialApi.getFeaturedRooms()
			const { status, data, statusText } = response || {}
			if (status == 200) {
				this.featuredStore.storeRooms(data)
			} else {
				const action = 'loading the featured rooms'
				handleApiError({ status, data, statusText, action })
			}
		},
	},
}
</script>

<style scoped lang="scss">
.feature {
	// border: solid 1px lightblue;
	margin-bottom: 1.5rem;
	padding: 0 var(--pp);
	box-sizing: content-box;

	position: relative; // temp
}
// .feature::after {
// 	content: '';
// 	background: rgba(0, 0, 255, 0.2);
// 	width: calc(100% - 1.2rem);
// 	position: absolute;
// 	top: 0;
// 	pointer-events: none;
// 	height: 100%;
// }

// Image grid
.feature .grid {
	margin-bottom: 0.4rem;
}

// Text wrap
.feature .content {
	// max-width: 600px; // Needs pixel value - see FeatureGrid <grid>
	max-width: 6rem;
	margin: 0 auto;
	margin-top: 0.3rem;
}
.feature .content.center {
	text-align: center;
}
.feature .content.center .room-actions {
	justify-content: center;
}

// Description
.feature .small {
	margin-bottom: 0.2rem;
}

// Actions
.feature .room-actions {
	margin-bottom: 0.2rem;
}

// Intro
.feature .intro {
	margin-bottom: 0.1rem;
}

// Collapsed text & Read more link
.feature .read-more::after {
	content: '+ Read more';
}
.feature .intro.expand + .read-more::after {
	content: 'Less';
}
.feature .intro:not(.expand):deep() .collapsable,
.feature .intro:not(.expand):deep() p:not(:first-child) {
	display: none;
}

// Divider
.feature:deep() .icn-arthur-emblem {
	display: block;
	margin: 1rem auto 0 auto;
	fill: $black-10;
}

/**
 * Responsive
 */

@media only screen and (max-height: 700px) {
	.feature {
		margin-bottom: 15vh;
	}
	.feature:deep() .icn-arthur-emblem {
		margin-top: 15vh;
	}
}
</style>
