<template>
	<div class="act-gen" :class="{ dark }">
		<BaseIcon :name="icnName" />
	</div>
</template>

<script>
// Components
import BaseIcon from '@/components/BaseIcon'

export default {
	name: 'ActionGeneral',
	components: { BaseIcon },
	props: {
		icnName: {
			type: String,
			required: true,
		},
		dark: Boolean,
	},
}
</script>

<style scoped lang="scss">
.act-gen {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark version
.act-gen.dark:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-gen:hover:deep() .icn {
		fill: $primary;
	}
}
</style>
