<template>
	<div class="room-actions">
		<ActionGeneral icnName="collect-outline" @click="onCollectClick" />
		<ActionLink entityType="room" :entity="room" :path="`/room/${room.id}`" />
		<BaseCount :count="room.artworkCount" />
	</div>
</template>

<script>
// Components
import ActionGeneral from '@/components/ActionGeneral'
import ActionLink from '@/components/ActionLink'
import BaseCount from '@/components/BaseCount'

// Internal
import { nonUserBlock, comingSoon } from '@/use/General'

export default {
	name: 'RoomActions',
	components: {
		ActionGeneral,
		ActionLink,
		BaseCount,
	},
	props: {
		room: Object,
	},
	data() {
		return {}
	},
	computed: {},
	created() {},
	methods: {
		onCollectClick() {
			if (this.$isLoggedIn) {
				comingSoon('Bookmarking rooms')
			} else {
				nonUserBlock('bookmark rooms')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.room-actions {
	// background: lightblue;
	min-width: 1.5rem;
	height: 0.5rem;
	display: flex;
	align-items: center;
}
.room-actions .act-gen {
	margin-left: -0.19rem;
}
.room-actions .count {
	margin-left: 0.1rem;
}
</style>
